import HomePage from "src/components/page/home"
import React from "react"

export default () => (
  <HomePage
    title={
      <>
        WATCH <i>THE HOUSE FROM…</i> <br /> ON MAXIMUM EFFORT CHANNEL
      </>
    }
    subtitle={
      <>
        What happens when your house is famous? <i>THE HOUSE FROM...</i>{" "}
        explores the unique experience of living in iconic homes from everyone's
        favorite movies & TV shows. Narrated by <i>My Name is Earl</i> 's Jason
        Lee. Premieres October 27.
      </>
    }
    videoUrl="https://www.youtube.com/embed/oBulIaemnRs?si=POMIFQWs0p6s9t-b"
    videoAlt="THE HOUSE FROM... on Maximum Effort Channel"
    // seriesName={"THE HOUSE FROM..."}
    channelTitle="AVAILABLE TO BUY OCTOBER 28th"
  />
)
